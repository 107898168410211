<template>
  <div>
    <FrsRouterBackLink :target="'phbb'">
      <template #text>
        <IxRes>phbb.buttons.backToPhbb</IxRes>
      </template>
    </FrsRouterBackLink>

    <ContentWrapper>
      <TargetValueSelection v-model="targetValue" />

      <BoolSwitch v-model="entireField">
        <IxRes>phbb.upload.entireField</IxRes>
      </BoolSwitch>
      <br>

      <CollapsibleSection v-model="orderSelectionShow">
        <template #header>
          <IxRes>phbb.calibration.selectedSamplingOrders</IxRes>
        </template>
        <HelpBox>
          <IxRes>phbb.calibration.order.onlyCompleted</IxRes>
        </HelpBox>
        <VueSelectize
          v-model="selectedSamplingOrders"
          :options="orderOptions"
          name="samplingOrders"
          required multiple
          allow-empty ordered
        />
        <OrderSelection />
      </CollapsibleSection>

      <CollapsibleSection v-model="mapSelectionShow">
        <HelpBox>
          <IxRes>phbb.calibration.descriptions.selectedInterpolationMaps</IxRes>
        </HelpBox>
        <template #header>
          <IxRes>phbb.calibration.selectedInterpolationMaps</IxRes>
        </template>
        <InterpolationMapSelection v-model="selectedMaps" :available-maps="availableMaps" />
      </CollapsibleSection>

      <HelpBox v-if="fieldStates.fieldStateOrderSelected === 'error'" type="danger">
        <IxRes>phbb.calibration.select.order.nothingSelected</IxRes>
      </HelpBox>

      <HelpBox v-if="fieldStates.fieldStateMapSelected === 'error'" type="danger">
        <IxRes>phbb.calibration.select.map.nothingSelected</IxRes>
      </HelpBox>

      <template #footer>
        <IxButton
          submit large
          @click="startCalibration"
        >
          <IxRes>phbb.calibration.create</IxRes>
        </IxButton>
        <IxButton large @click="launchMapManagement">
          <IxRes>phbb.wizard.button.launchMapManagement</IxRes>
        </IxButton>
      </template>
    </ContentWrapper>
    <FrsLoadingIndicator :loading="loading" />
  </div>
</template>

<script>
import {orderBy} from 'lodash'
import {mapState, mapActions} from 'vuex'

import {smarterGet} from '@helpers/vuex/data-loading'
import {mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import FormPartMixin from '@components/forms/FormPartMixin'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import CollapsibleSection from '@components/CollapsibleSection'
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import ContentWrapper from '@frs/components/phbb/widgets/ContentWrapper'
import VueSelectize from '@components/VueSelectize'
import HelpBox from '@components/help/HelpBox'
import BoolSwitch from 'src/vue/components/forms/BoolSwitch'
import IxButton from 'src/vue/components/IxButton'

import TargetValueSelection from '../widgets/TargetValueSelection.vue'
import InterpolationMapSelection from '../widgets/InterpolationMapSelection'
import OrderSelection from './OrderSelection'

export default {
  components: {
    CollapsibleSection,
    FrsRouterBackLink,
    ContentWrapper,
    VueSelectize,
    BoolSwitch,
    IxButton,
    HelpBox,
    TargetValueSelection,
    InterpolationMapSelection,
    OrderSelection,
    FrsLoadingIndicator
  },
  mixins: [
    FormPartMixin,
    MirrorRouteMixin
  ],
  data () {
    return {
      orderSelectionShow: false,
      mapSelectionShow: false,
      availableMaps: [],
      loading: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.common.entireField',
      'data.common.targetValue',
      'data.common.selectedMaps',
      'data.calibration.availableOrders',
      'data.calibration.selectedOrders'
    ]),
    ...mapState('fieldRecordSystem', {
      harvestYear: state => state.userSettings.harvestYear
    }),
    ...mapState('fieldRecordSystem/navigation', {
      fieldId: state => state.location.fieldId
    }),
    filteredOrders () {
      return Object.values(this.availableOrders).filter(x => x.targetValue === this.targetValue)
    },
    orderOptions () {
      // selectable orders are filtered by target value
      const filteredOrders = this.filteredOrders.map(x => ({value: x.id, text: x.name, label: this.$i18n.format(x.created, 'date')}))
      return orderBy(filteredOrders, ['label'], ['desc'])
    },
    selectedSamplingOrders: {
      get () {
        return this.selectedOrders.filter(id => this.filteredOrders.map(filteredOrder => filteredOrder.id).includes(id)).map(x => ({value: this.availableOrders[x].id, text: this.availableOrders[x].name, label: this.$i18n.format(this.availableOrders[x].created, 'date')}))
      },
      set (value) {
        this.selectedOrders = value.map(x => x.value)
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/phbb', [
      'calibrateSensorData',
      'loadCompletedFullOrders'
    ]),
    async startCalibration () {
      this.validate()
      this.validateOrderSelection()
      this.validateMapSelection()
      await this.$nextTick()
      if (this.state !== 'success') return

      try {
        this.loading = true
        const result = await this.calibrateSensorData()
        if (result.success) {
          notifications.success(this.$i18n.translate('phbb.calibration.notifications.success'))
        } else {
          notifications.error(this.$i18n.translate('phbb.calibration.notifications.noMapsCreated'))
          console.error(result.rLog)
        }
      } catch (error) {
        notifications.error(this.$i18n.translate('phbb.calibration.notifications.error'))
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    validateOrderSelection () {
      this.$set(this.fieldStates, 'fieldStateOrderSelected', this.selectedOrders.length > 0 ? 'success' : 'error')
    },
    validateMapSelection () {
      this.$set(this.fieldStates, 'fieldStateMapSelected', this.selectedMaps.length > 0 ? 'success' : 'error')
    },
    launchMapManagement () {
      this.$router.push({name: this.createParallelRoute('mapManagement')})
    }
  },
  watch: {
    selectedMaps () {
      this.$set(this.fieldStates, 'fieldStateMapSelected', 'success')
    },
    selectedOrders () {
      this.$set(this.fieldStates, 'fieldStateOrderSelected', 'success')
    },
    filteredOrders (value) {
      this.selectedOrders = this.selectedSamplingOrders.filter(id => value.map(order => order.id).includes(id))
    }
  },
  async mounted () {
    this.loadCompletedFullOrders()

    this.availableMaps = await smarterGet('/api/v2/map-management/fields/{fieldId}/interpolation-maps/{harvestYear}', {
      id: 'phbb.calibration.maps',
      expiry: 0,
      inputs: {
        fieldId: () => this.fieldId,
        harvestYear: () => this.harvestYear
      },
      onResult (maps) {
        return maps
      }
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
