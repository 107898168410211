<template lang="html">
  <div class="form-group">
    <label v-if="hasSlotData" class="control-label">
      <slot />
    </label>
    <div class="btn-group bool-switch-buttons">
      <button
        class="btn" :class="[value === true ? 'btn-success' : 'btn-default']"
        :disabled="disabled"
        @click="$emit('input', true)"
      >
        <i class="fa fa-check" />
      </button>
      <button
        class="btn" :class="[value === false ? 'btn-danger' : 'btn-default']"
        :disabled="disabled"
        @click="$emit('input', false)"
      >
        <i class="fa fa-close" />
      </button>
      <button
        v-if="nullable" class="btn"
        :class="[value === null ? 'btn-info' : 'btn-default']"
        :disabled="disabled" @click="$emit('input', null)"
      >
        <i class="fa fa-question" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    nullable: Boolean,
    disabled: Boolean
  },
  computed: {
    hasSlotData () {
      return this.$slots.default
    }
  }
}
</script>

<style lang="scss" scoped>
.bool-switch-buttons {
  display: block;
}
</style>
