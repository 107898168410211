var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"action-buttons"},[_c('IxButton',{attrs:{"large":""},on:{"click":function($event){_vm.selectedMaps = Object.keys(_vm.availableMapsLookup)}}},[_c('IxRes',[_vm._v("phbb.calibration.selectAllMaps")])],1),_c('IxButton',{attrs:{"large":""},on:{"click":function($event){_vm.selectedMaps = []}}},[_c('IxRes',[_vm._v("phbb.calibration.deselectAllMaps")])],1)],1),_c('SimpleTable',{staticClass:"interpolation-map-table",attrs:{"columns":_vm.interpolationMapColumns,"data":Object.values(_vm.availableMapsLookup)},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var id = ref.value;
return [_c('BsCheckbox',{attrs:{"value":id},model:{value:(_vm.selectedMaps),callback:function ($$v) {_vm.selectedMaps=$$v},expression:"selectedMaps"}})]}},{key:"name",fn:function(ref){
var name = ref.value;
var row = ref.row;
return [_c('a',{staticClass:"clickable-name",on:{"click":function($event){_vm.visibleMap = _vm.visibleMap === row.id ? null : row.id}}},[_vm._v(_vm._s(name))])]}},{key:"blockSize",fn:function(ref){
var blockSize = ref.value;
return [_vm._v("\n      "+_vm._s(blockSize)+"\n    ")]}},{key:"pixelSize",fn:function(ref){
var pixelSize = ref.value;
return [_vm._v("\n      "+_vm._s(pixelSize)+"\n    ")]}},{key:"mappingDate",fn:function(ref){
var mappingDate = ref.value;
return [_vm._v("\n      "+_vm._s(_vm.$i18n.format(mappingDate, 'date'))+"\n    ")]}},{key:"createdAt",fn:function(ref){
var createdAt = ref.value;
return [_vm._v("\n      "+_vm._s(_vm.$i18n.format(createdAt, 'date'))+"\n    ")]}},{key:"syndicated",fn:function(ref){
var id = ref.value;
return [_c('IxButton',{attrs:{"icon":_vm.visibleMap === id ? 'eye' : 'eye-slash'},on:{"click":function($event){_vm.visibleMap = _vm.visibleMap === id ? null : id}}})]}}])}),(_vm.visibleMap)?_c('InterpolationMapLayer',{attrs:{"map":_vm.availableMapsLookup[_vm.visibleMap]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }