<template>
  <div>
    <SimpleTable :columns="columns" :data="tableData">
      <template #probingDate="{value}">
        {{ $i18n.format(value, 'date') }}
      </template>
    </SimpleTable>
    <IxVectorLayer
      :features="pointFeatures"
      :vector-style="styles.point"
      layer-id="points"
    />
  </div>
</template>

<script>
import {pick} from 'lodash'
import {mapState, mapGetters} from 'vuex'

import {probingResultPreview} from '@frs/map-styles'

import SimpleTable from '@components/table/SimpleTable'
import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    SimpleTable,
    IxVectorLayer
  },
  data () {
    return {
      targetValueDataFields: {
        ph: ['ph'],
        humus: ['humus'],
        soilTexture: ['sand', 'silt', 'clay']
      }
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/phbb', {
      selectedOrders: state => state.data.calibration.selectedOrders,
      availableOrders: state => state.data.calibration.availableOrders
    }),
    ...mapGetters('fieldRecordSystem/phbb', [
      'samplingPoints'
    ]),
    columns () {
      const cols = {
        orderName: {title: 'Auftragsname', sortable: true},
        sampleId: {title: 'Nummer', sortable: true},
        probingDate: {title: 'Datum', sortable: true}
      }

      this.selectedOrders.forEach((id, i) => {
        const currentOrder = this.availableOrders[id]
        const targetValue = currentOrder.targetValue

        const dataFields = this.targetValueDataFields[targetValue]

        dataFields.forEach((field, i) => {
          cols[field] = {title: this.$i18n.translate(`phbb.calibration.select.order.dataField_${field}`), sortable: true}
        })
      })

      return cols
    },
    tableData () {
      const samplingPoints = this.selectedOrders.reduce((acc, id) => {
        const order = this.availableOrders[id]
        const samplingPointsPerOrder = order.features.map(feature => {
          const dataProps = pick(feature.properties, this.targetValueDataFields[order.targetValue])
          const values = Object.keys(dataProps).map(key => ({name: key, value: dataProps[key]}))

          const data = {
            orderName: this.availableOrders[id].name,
            probingDate: this.availableOrders[id].created,
            sampleId: feature.properties.sampleId
          }

          values.forEach((value, i) => {
            data[value.name] = value.value
          })

          return data

        })
        return acc.concat(samplingPointsPerOrder)
      }, [])
      return samplingPoints
    },
    pointFeatures () {
      return this.selectedOrders.reduce((acc, id) => {
        acc = acc.concat(this.availableOrders[id].features)
        return acc
      }, [])
    },
    styles () {
      return {
        point: probingResultPreview
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
