<template>
  <div>
    <div class="action-buttons">
      <IxButton large @click="selectedMaps = Object.keys(availableMapsLookup)">
        <IxRes>phbb.calibration.selectAllMaps</IxRes>
      </IxButton>
      <IxButton large @click="selectedMaps = []">
        <IxRes>phbb.calibration.deselectAllMaps</IxRes>
      </IxButton>
    </div>
    <SimpleTable
      class="interpolation-map-table"
      :columns="interpolationMapColumns"
      :data="Object.values(availableMapsLookup)"
    >
      <template #id="{value: id}">
        <BsCheckbox v-model="selectedMaps" :value="id" />
      </template>
      <template #name="{value: name, row}">
        <a class="clickable-name" @click="visibleMap = visibleMap === row.id ? null : row.id">{{ name }}</a>
      </template>
      <template #blockSize="{value: blockSize}">
        {{ blockSize }}
      </template>
      <template #pixelSize="{value: pixelSize}">
        {{ pixelSize }}
      </template>
      <template #mappingDate="{value: mappingDate}">
        {{ $i18n.format(mappingDate, 'date') }}
      </template>
      <template #createdAt="{value: createdAt}">
        {{ $i18n.format(createdAt, 'date') }}
      </template>
      <template #syndicated="{value: id}">
        <IxButton :icon="visibleMap === id ? 'eye' : 'eye-slash'" @click="visibleMap = visibleMap === id ? null : id" />
      </template>
    </SimpleTable>

    <InterpolationMapLayer
      v-if="visibleMap"
      :map="availableMapsLookup[visibleMap]"
    />
  </div>
</template>

<script>
import {keyBy} from 'lodash'

import SimpleTable from '@components/table/SimpleTable'
import BsCheckbox from 'src/vue/components/bootstrap/BsCheckbox'
import IxButton from 'src/vue/components/IxButton'
import InterpolationMapLayer from '@frs/components/base/InterpolationMapLayer'

export default {
  components: {
    SimpleTable,
    BsCheckbox,
    IxButton,
    InterpolationMapLayer
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    availableMaps: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      visibleMap: null
    }
  },
  computed: {
    availableMapsLookup () {
      return keyBy(this.availableMaps, 'id')
    },
    interpolationMapColumns () {
      return {
        id: {title: '', sortable: false},
        name: {title: this.$i18n.translate('Common.SR_Common.Name'), sortable: true},
        layerName: {title: this.$i18n.translate('frs.mapManagement.details.interpolationMaps.layer'), sortable: true},
        pixelSize: {title: this.$i18n.translate('frs.mapManagement.details.interpolationMaps.pixelSize')},
        blockSize: {title: this.$i18n.translate('frs.mapManagement.details.interpolationMaps.blockSize')},
        mappingDate: {title: this.$i18n.translate('frs.mapManagement.details.interpolationMaps.mappingDate'), sortable: true},
        createdAt: {title: this.$i18n.translate('Common.SR_Common.CreatedAt'), sortable: true},
        syndicated: {
          title: '',
          value: x => x.id
        }
      }
    },
    selectedMaps: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }
}
</script>

<style lang="scss" scoped>
.action-buttons {
  margin: 12px 0;
  > * {
    margin-right: 4px;
  }
}

.clickable-name {
  cursor: pointer;
}

// ugly hack to overcome bootstrap styling
::v-deep .interpolation-map-table .checkbox {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
</style>
