<template>
  <div class="content-wrapper">
    <div class="header-container">
      <slot name="header" />
    </div>
    <div class="title-container">
      <h4><slot name="title" /></h4>
      <span><slot name="description" /></span>
    </div>
    <div class="content-container">
      <slot />
    </div>
    <div class="footer-container">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin: 4px;

  .header-container,
  .footer-container {
    display: flex;
    justify-content: space-between;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }
}
</style>
