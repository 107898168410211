<template>
  <LabelAndMessage>
    <template #label>
      <IxRes>phbb.calibration.targetValue</IxRes>
    </template>
    <VueSelectize
      v-model="selectedTarget"
      :options="targetOptions"
      name="target"
      required
    />
    <HelpBox>
      <IxRes>phbb.calibration.description.targetValue</IxRes>
    </HelpBox>
  </LabelAndMessage>
</template>

<script>
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import VueSelectize from '@components/VueSelectize'
import HelpBox from '@components/help/HelpBox'
export default {
  components: {
    HelpBox,
    LabelAndMessage,
    VueSelectize
  },
  props: {
    value: {
      type: String,
      default: () => 'humus'
    }
  },
  computed: {
    targetOptions () {
      return ['humus', 'soilTexture', 'ph'].map(x => ({value: x, text: this.$i18n.translate(`phbb.calibration.targetValue_${x}`)}))
    },
    selectedTarget: {
      get () { return {value: this.value, text: this.$i18n.translate(`phbb.calibration.targetValue_${this.targetValue}`)} },
      set (value) { this.$emit('input', value.value) }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
